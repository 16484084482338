import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

export class LanguageDropdown extends AbstractComponent
{
	public static selector: string = 'language-dropdown';

	init() {
		window.history.replaceState(
			{},
			"",
			this.getDI().getUrlModule().removeUrlGetParam('forceLang')
		);
	}
}

new ComponentLoader(LanguageDropdown);
